@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --bs-blue: #1b69dfed;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #111213;
  --bs-primary: #25408f;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-fade-info: rgba(231, 250, 248, 0.705);
  --bs-fade-blue: rgba(152, 243, 232, 0.486);
}

:root {
  --primary-transition-time: 0.2s ease;
  --primary-box-shadow: 0 10px 31px 0 rgb(7 152 255 / 9%);
}

/* GLOBAL STYLES */
body {
  font-family: "Open Sans Light";
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

a {
  font-family: "Open Sans Bold";
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p,
h1,
h2,
h3 {
  margin: 0px;
  padding: 0px;
}

.propertyBold {
  /* color: green; */
  font-size: 22px;
}

.logo-image {
  width: 100px;
}

.contact {
  background-color: green;
}